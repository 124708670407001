<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>审批待办</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.keywords" placeholder="输入消息标题" clearable/>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.isRead" placeholder="选择状态" filterable clearable>
                        <Option v-for="item in isReadList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a v-if="permissionButton.process && row.status==='待审批'" @click="handleProcess(row, index)">审批</a>
                        <a v-if="permissionButton.view && row.status!=='待审批'" @click="handleView(row, index)">详情</a>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <view-back-log v-model="showViewModel" :data="data"></view-back-log>
        <position v-model="showPositionModel" :backlogId="id" :sourceId="sourceId" :sourceType="sourceType" v-on:getBackLog="getBackLog"></position>
        <opportunity v-model="showOpportunityModel" :backlogId="id" :sourceId="sourceId" :sourceType="sourceType" v-on:getBackLog="getBackLog"></opportunity>
        <goal v-model="showGoalModel" :backlogId="id" :sourceId="sourceId" :sourceType="sourceType" v-on:getBackLog="getBackLog"></goal>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqGetBackLog} from "@/api/admin-api";
import viewBackLog from './view'
import position from "./position";
import opportunity from './opportunity'
import goal from "./goal";

export default {
    name: 'backlogList',
    components: {viewBackLog, position,opportunity,goal},
    data() {
        return {
            id: null,
            data: {},
            filter: {
                keywords: '',
                isRead: null,
            },
            isReadList: [
                {
                    id: '待审批',
                    name: '待审批',
                },
                {
                    id: '通过',
                    name: '通过',
                },
                {
                    id: '驳回',
                    name: '驳回',
                },
            ],
            showViewModel: false,
            showPositionModel: false,
            showOpportunityModel: false,
            showGoalModel: false,
            sourceId: null,
            sourceType: null,
            loading: false,
            dataList: [],
            permissionButton: {
                process: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    width: 100,
                    align: 'center',
                },
                {
                    title: '标题',
                    key: 'title',
                    minWidth: 200,
                },
                {
                    title: '内容',
                    key: 'content',
                    tooltip: 'ture',
                    minWidth: 360,
                },
                {
                    title: '状态',
                    key: 'status',
                    render: (h, params) => {
                        return h('span', [
                            h(
                                'span',
                                {
                                    style: {
                                        color:
                                            params.row.status === '待处理' ? '#ed4014' : '',
                                    },
                                },
                                params.row.status === '待处理' ? '待处理' : params.row.status
                            ),
                        ])
                    },
                    width: 100,
                },
                {
                    title: '提交人',
                    key: 'senderName',
                    width: 100,
                },
                {
                    title: '发送时间',
                    key: 'sendTime',
                    width: 180,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 100,
                    align: 'center',
                    fixed: 'right',
                },
            ],
        }
    },
    methods: {
        getBackLog() {
            this.loading = true;
            this.dataList = [];

            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
                isRead: this.filter.isRead,
            };
            reqGetBackLog(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.keywords = '';
            this.filter.isRead= null;

            let para = {
                pageNum: 1,
                pageSize: 20,
                keywords: this.filter.keywords,
                isRead: this.filter.isRead,
            };
            reqGetBackLog(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleProcess(row, index) {
            this.id = row.id;
            this.sourceId = row.sourceId;
            this.sourceType = row.sourceType;
            if (row.sourceType === 'position_interview_performance' || row.sourceType === 'position_entry_performance'
                || row.sourceType === 'position_entry_balPerformance' || row.sourceType === 'position_RPO_other_cost'
                || row.sourceType === 'position_RPO_other_performance' || row.sourceType === 'position_RPO_other_balPerformance') {
                this.showPositionModel = true;
            }
            if (row.sourceType === 'opportunity_cost' || row.sourceType === 'opportunity_performance') {
                this.showOpportunityModel = true;
            }
            if (row.sourceType === 'goal_confirm' || row.sourceType === 'goal_change') {
                this.showGoalModel = true;
            }
        },
        handleView(row, index) {
            this.data = row
            this.showViewModel = true
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getBackLog();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getBackLog();
        },
        doSearching() {
            this.pageNum = 1;
            this.getBackLog();
        }
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton);
        this.getBackLog();
    }
}
</script>
