<template>
    <Modal v-model="showForm" title="商机审批" width="680" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">商机信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="客户名称">{{ viewForm.customerName }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="商机类型">{{ viewForm.typeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="商机金额">{{ viewForm.amount }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="创建人">{{ viewForm.createByName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="创建时间">{{ viewForm.createTime }}</FormItem>
                </Col>
            </Row>
            <div class="divider">提审进度<span class="tips">注：录入成本提交后，之前步骤不可编辑</span></div>
            <Tabs type="card" v-model="current" @on-click="submitContract" :animated="false">
                <TabPane label="1. 提交合同" name="0">
                    <FormItem class="multiline-content" label="合同附件">
                        <div v-for="item in contractImgData" >
                            <a @click="handleDownload(item)">{{ item.name }}</a>
                        </div>
                    </FormItem>
                    <FormItem class="multiline-content" label="合同备注">
                        {{ viewForm.contractMemo }}
                    </FormItem>
                </TabPane>
                <TabPane label="2. 提交票据" name="1">
                    <FormItem label="是否开票">
                        <div v-if="viewForm.needInvoice === 1">是</div>
                        <div v-if="viewForm.needInvoice === 0">否</div>
                    </FormItem>
                    <FormItem class="multiline-content" label="发票附件">
                        <div v-for="item in invoiceImgData">
                            <a @click="handleDownload(item)">{{ item.name }}</a>
                        </div>
                    </FormItem>
                    <FormItem class="multiline-content" label="票据备注">
                        {{ viewForm.invoiceMemo }}
                    </FormItem>
                </TabPane>
                <TabPane label="3. 确认回款" name="2">
                    <FormItem label="回款金额">{{ viewForm.receive }}</FormItem>
                    <FormItem class="multiline-content" label="回款附件">
                        <div class="upload-image-list" v-for="item in receiveImgData" >
                            <img :src="item">
                            <div class="upload-image-list-cover">
                                <Icon type="ios-eye" @click.native="handlePreview(item)"></Icon>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem class="multiline-content" label="回款备注">
                        {{ viewForm.receiveMemo }}
                    </FormItem>
                </TabPane>
                <TabPane label="4. 录入成本" name="3">
                    <FormItem label="成本金额">{{ viewForm.cost }}</FormItem>
                    <FormItem label="成本附件">
                        <div class="upload-image-list" v-for="item in costImgData" >
                            <img :src="item">
                            <div class="upload-image-list-cover">
                                <Icon type="ios-eye" @click.native="handlePreview(item)"></Icon>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem class="multiline-content" label="成本备注">
                        {{ viewForm.costMemo }}
                    </FormItem>
                </TabPane>
                <TabPane label="5. 录入业绩" name="4" :disabled="disablePerformance">
                    <FormItem label="业绩类型">{{ viewForm.performanceTypeName }}</FormItem>
                    <FormItem label="业绩金额">{{ viewForm.performance }}</FormItem>
                    <FormItem label="完成时间">{{ viewForm.completionTime }}</FormItem>
                    <FormItem class="multiline-content" label="业绩备注">{{ viewForm.performanceMemo }}</FormItem>
                </TabPane>
            </Tabs>
            <div class="divider">审批结论</div>
            <div class="custom-form-section">
                <FormItem class="plain-item-margin" label="审批结论" required>
                    <Select v-model="viewForm.status" placeholder="请选择" filterable clearable>
                        <Option value="通过">通过</Option>
                        <Option value="驳回">驳回</Option>
                    </Select>
                </FormItem>
                <FormItem label="驳回理由" required v-if="viewForm.status === '驳回'">
                    <Input type="textarea" v-model="viewForm.comment" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit />
                </FormItem>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imageUrl" style="width: 100%" />
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="default" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {
    reqGetEnterCostScheduleById,
    reqGetEnterCostScheduleBySourceId,
    reqGetEnterPerformanceScheduleById,
    reqGetEnterPerformanceScheduleBySourceId,
    reqGetOpportunityBySourceId,
    reqProcessBacklog
} from "@/api/admin-api";
import {downloadFile, uploadFileURL} from "@/api/system-api";

export default {
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        backlogId: null,
        sourceId: null,
        sourceType: null,
    },
    data() {
        return {
            current: '0',
            showPreview: false,
            imageUrl: '',
            viewForm: {
                id: null,
                status: null,
            },
            viewFormRule:{
                status: [
                    {required: true, message: '请选择审批结论', trigger: 'blur'}
                ],
                comment: [
                    {required: true, message: '请输入驳回理由', trigger: 'blur'}
                ]
            },
            contractImgData: [],
            invoiceImgData: [],
            costImgData: [],
            receiveImgData: [],
            enterPerformanceScheduleList: [],
            enterCostScheduleList: [],
            disablePerformance: false,
            show: true,
            header: {},
            uploadFiles: uploadFileURL,
            uploadInvoice: false,
            loading: false,
            showForm: this.value,
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['viewForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.viewForm.status === '驳回' && this.viewForm.comment === undefined) {
                    this.$Message.error('请输入驳回理由')
                    this.loading = false;
                    return false;
                }

                let params = {
                    backlogId: this.backlogId,
                    sourceId: this.sourceId,
                    sourceType: this.sourceType,
                    status: this.viewForm.status,
                    comment: this.viewForm.comment
                };
                reqProcessBacklog(params).then((res) => {
                    this.loading = false;
                    if (res.data.data === 1) {
                        this.$Message.success('审批成功');
                        this.$emit('getBackLog');
                        this.closeModal(false);
                    } else if(res.data.data === -1){
                        this.$Message.error('请选择审批结论')
                    } else {
                        this.$Message.error('审批失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('审批失败');
                })
            });
        },
        submitContract(name) {
            this.current = name
        },
        getOpportunityBySourceId() {
            reqGetOpportunityBySourceId({id: this.sourceId}).then((res) => {
                this.viewForm = res.data.data;
                let x = JSON.parse(res.data.data.resource)
                for (let i = 0; i < x.contract.length; i++) {
                    this.contractImgData.push({currentName: x.contract[i].currentName, name: x.contract[i].name})
                }
                for (let i = 0; i < x.invoice.length; i++) {
                    this.invoiceImgData.push({currentName: x.invoice[i].currentName, name: x.invoice[i].name})
                }
                for (let i = 0; i < x.receive.length; i++) {
                    this.showReceive(x.receive[i].currentName)
                }
                for (let i = 0; i < x.cost.length; i++) {
                    this.showCostImg(x.cost[i].currentName)
                }
                if (this.viewForm.opportunityApprovalType === '录入成本'){
                    this.disablePerformance = true
                    this.submitContract('3')
                } else {
                    this.submitContract('4')
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        getEnterCostScheduleById() {
            reqGetEnterCostScheduleBySourceId({id: this.sourceId}).then((res) => {
                this.enterCostScheduleList = res.data.data
            }).catch(() => {
                this.enterCostScheduleList = []
            })
        },
        getEnterPerformanceScheduleById() {
            reqGetEnterPerformanceScheduleBySourceId({id: this.sourceId}).then((res) => {
                this.enterPerformanceScheduleList = res.data.data
            }).catch(() => {
                this.enterCostScheduleList = []
            })
        },
        //展示图片
        showReceive(val) {
            let para = {
                type: 'private',
                fileName: val
            }
            let that = this;
            downloadFile(para).then((res) => {
                let name = window.URL.createObjectURL(new Blob([res.data]))
                that.receiveImgData.push(name)
            }).catch(() => {
                that.receiveImgData = null;
            });
        },
        //展示图片
        showCostImg(val) {
            let para = {
                type: 'private',
                fileName: val
            }
            let that = this;
            downloadFile(para).then((res) => {
                let name = window.URL.createObjectURL(new Blob([res.data]))
                that.costImgData.push(name)
            }).catch(() => {
                that.costImgData = null;
            });
        },
        handlePreview(val) {
            this.imageUrl = val
            this.showPreview = true;
        },
        handleDownload(val){
            let para = {}
            if (val !== undefined) {
                para = {
                    type: 'private',
                    fileName: val.currentName
                }
            }
            downloadFile(para).then((res) => {
                if (res.data.size > 0) {
                    var blob = new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8',
                    }) //type这里表示xlsx类型
                    var downloadElement = document.createElement('a')
                    var href = window.URL.createObjectURL(blob) //创建下载的链接
                    downloadElement.href = href
                    downloadElement.download = val.name//下载后文件名
                    document.body.appendChild(downloadElement)
                    downloadElement.click() //点击下载
                    document.body.removeChild(downloadElement) //下载完成移除元素
                    window.URL.revokeObjectURL(href) //释放掉blob对象
                } else {
                    this.$Message.error('下载失败')
                }
            }).catch(() => {
                this.$Message.error('下载失败')
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.contractImgData= [];
                this.invoiceImgData= [];
                this.costImgData= [];
                this.receiveImgData= [];
                this.enterPerformanceScheduleList= [];
                this.enterCostScheduleList= [];
                this.getOpportunityBySourceId()
                this.getEnterCostScheduleById()
                this.getEnterPerformanceScheduleById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
