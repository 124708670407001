<template>
    <Modal v-model="showForm" title="目标审批" width="680" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" :rules="viewFormRule" label-colon :label-width="80">
            <div class="divider">目标信息</div>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="周期">{{ viewForm.startDate }} ～ {{ viewForm.endDate }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="目标类型">{{ viewForm.typeName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                    <FormItem label="状态">{{ viewForm.status }}</FormItem>
                </Col>
                <Col span="12">
                    <FormItem label="创建人">{{ viewForm.createByName }}</FormItem>
                </Col>
            </Row>
            <Row :gutter="16">
                <Col span="12">
                <FormItem label="创建时间">{{ viewForm.createTime }}</FormItem>
            </Col>
            <Col span="12">
                    <FormItem label="更新时间">{{ viewForm.updatedTime }}</FormItem>
                </Col>
            </Row>

            <div class="divider">目标明细</div>
            <div class="table">
                <div class="table-list">
                    <Table stripe :columns="columns" :data="dataList" :loading="loading"></Table>
                </div>
            </div>

            <div class="divider">审批记录</div>
            <FormItem label="审批历史">
                <Timeline>
                    <TimelineItem v-for="item in verifyList" :value="item.id" :key="item.id">
                        <div class="status">{{ item.status }}<span v-if="item.comment">{{ item.comment }}</span></div>
                        <div class="operator">{{ item.operatorName }} {{ item.updatedTime }}</div>
                    </TimelineItem>
                </Timeline>
            </FormItem>

            <div class="divider">审批结论</div>
            <div class="custom-form-section">
                <FormItem label="审批结论" required>
                    <Select class="plain-item-margin" v-model="viewForm.status" placeholder="请选择" filterable clearable>
                        <Option value="通过">通过</Option>
                        <Option value="驳回">驳回</Option>
                    </Select>
                </FormItem>
                <FormItem label="驳回理由" required v-if="viewForm.status === '驳回'">
                    <Input type="textarea" v-model="viewForm.comment" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit />
                </FormItem>
            </div>
        </Form>

        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="default" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>

import {
    reqGetGoalBySourceId,
    reqGetGoalListBySourceId,
    reqGetGoalVerify, reqGetGoalVerifyBySourceId, reqProcessBacklog
} from "@/api/admin-api";

export default {
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        backlogId: null,
        sourceId: null,
        sourceType: null,
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            viewForm: {},
            viewFormRule: {},
            dataList: [],
            verifyList: [],
            columns: [
                {
                    title: '干系人',
                    key: 'stakeholderName',
                    width: 120
                },
                {
                    title: '所属组织',
                    key: 'deptName',
                    minWidth: 220
                },
                {
                    title: '金额(元)',
                    key: 'amount',
                    width: 150
                },
            ],
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            this.$refs['viewForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.viewForm.status === '驳回' && this.viewForm.comment === undefined) {
                    this.$Message.error('请输入驳回理由')
                    this.loading = false;
                    return false;
                }

                let params = {
                    backlogId: this.backlogId,
                    sourceId: this.sourceId,
                    sourceType: this.sourceType,
                    status: this.viewForm.status,
                    comment: this.viewForm.comment
                };
                reqProcessBacklog(params).then((res) => {
                    this.loading = false;
                    if (res.data.data === 1) {
                        this.$Message.success('审批成功');
                        this.$emit('getBackLog');
                        this.closeModal(false);
                    } else if(res.data.data === -1){
                        this.$Message.error('请选择审批结论')
                    } else {
                        this.$Message.error('审批失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('审批失败');
                })
            });
        },
        getGoalById() {
            reqGetGoalBySourceId({id: this.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.viewForm = res.data.data
                } else {
                    this.viewForm = {}
                }
            }).catch(() => {
                this.viewForm = {}
            })
            reqGetGoalListBySourceId({id: this.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.dataList = res.data.data
                } else {
                    this.dataList = []
                }
            }).catch(() => {
                this.dataList = []
            })
        },
        getGoalVerify() {
            reqGetGoalVerifyBySourceId({id: this.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.verifyList = res.data.data
                } else {
                    this.verifyList = []
                }
            }).catch(() => {
                this.verifyList = []
            })
        },
        closeModal(val) {
            this.imgData = null
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getGoalById()
                this.getGoalVerify()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
}
</script>
