<template>
    <Modal v-model="showForm" :title="modalTitle" width="860" :mask-closable="false">
        <Form class="plain-form" ref="detailForm" :model="detailForm" :rules="detailFormRule" label-colon :label-width="90">
            <div class="custom-form">
                <div class="divider">职位信息</div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="客户">{{ myPosition.customerName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="职位">{{ myPosition.name }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="类型">{{ myPosition.typeName }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="级别">{{ myPosition.levelName }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="金额">{{ myPosition.amount }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="顾问">{{ myPosition.consultantNames }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人">{{ myPosition.contact }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="联系电话">{{ myPosition.contactPhone }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="12">
                            <FormItem label="联系人职务">{{ myPosition.contactTitle }}</FormItem>
                        </Col>
                        <Col span="12">
                            <FormItem label="创建人">{{ myPosition.createByName }}</FormItem>
                        </Col>
                    </Row>
                </div>
                <div class="custom-form-section">
                    <Row :gutter="16">
                        <Col span="24">
                            <FormItem label="创建时间">{{ myPosition.createTime }}</FormItem>
                        </Col>
                    </Row>
                </div>

                <div class="divider">交付进度</div>
                <div class="custom-form-section">
                    <Tabs type="card" size="small" :animated="false" value="recommend" v-if="myPosition.typeName === '面试'">
                        <TabPane label="1. 推荐简历" name="recommend">
                            <Table stripe :columns="columns" :data="recommendResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.recommendMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 约面" name="booking">
                            <Table stripe :columns="columns" :data="bookingResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.bookingMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 到场" name="present">
                            <Table stripe :columns="columns" :data="presentResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.presentMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. 回款" name="receive">
                            <Table stripe :columns="columns" :data="receiveResumeList"></Table>
                            <div>
                                <FormItem label="回款金额">{{ detailForm.receive }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.receiveMemo }}</FormItem>
                                <FormItem label="证据截图">
                                    <div class="upload-image-list" v-for="(item, index) in receiveFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 录入业绩" name="performance">
                            <Table stripe :columns="columns" :data="performanceResumeList"></Table>
                            <div>
                                <FormItem label="业绩金额">{{ detailForm.performance }}</FormItem>
                                <FormItem label="完成时间">{{ detailForm.completionTime }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.performanceMemo }}</FormItem>
                                <FormItem label="提交时间">{{ detailForm.updatedTime }}</FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                    <Tabs type="card" size="small" :animated="false" value="recommend" v-if="myPosition.typeName === '入职'">
                        <TabPane label="1. 推荐简历" name="recommend">
                            <Table stripe :columns="columns" :data="recommendResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.recommendMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 初试" name="firstRound">
                            <Table stripe :columns="columns" :data="firstRoundResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.firstRoundMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 复试" name="secondRound">
                            <Table stripe :columns="columns" :data="secondRoundResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.secondRoundMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. Offer" name="offer">
                            <Table stripe :columns="columns" :data="offerResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.offerMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 入职" name="entry">
                            <Table stripe :columns="columns" :data="entryResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.entryMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="6. 首付款" name="payment">
                            <Table stripe :columns="columns" :data="paymentResumeList"></Table>
                            <div>
                                <FormItem label="金额">{{ detailForm.payment }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.paymentMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in paymentFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="7. 录入业绩" name="performance">
                            <Table stripe :columns="columns" :data="performanceResumeList"></Table>
                            <div>
                                <FormItem label="业绩金额">{{ detailForm.performance }}</FormItem>
                                <FormItem label="完成时间">{{ detailForm.completionTime }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.performanceMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="8. 过保" name="overDue">
                            <Table stripe :columns="columns" :data="overDueResumeList"></Table>
                            <div>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.overDueMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in overDueFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="9. 尾款" name="balance">
                            <Table stripe :columns="columns" :data="balanceResumeList"></Table>
                            <div>
                                <FormItem label="金额">{{ detailForm.balance }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.balanceMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in balanceFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="10. 录入业绩" name="balPerformance">
                            <Table stripe :columns="columns" :data="balPerformanceResumeList"></Table>
                            <div>
                                <FormItem label="业绩金额">{{ detailForm.balPerformance }}</FormItem>
                                <FormItem label="完成时间">{{ detailForm.balPerformanceTime }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.balPerformanceMemo }}</FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                    <Tabs type="card" size="small" :animated="false" value="handover" v-if="myPosition.typeName === 'RPO' || myPosition.typeName === '其他'">
                        <TabPane label="1. 已交付人数" name="handover">
                            <div>
                                <FormItem label="数量">{{ detailForm.handoverQuantity }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.handoverMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="2. 首付款" name="payment">
                            <div>
                                <FormItem label="金额">{{ detailForm.payment }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.paymentMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in paymentFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="3. 录入成本" name="cost">
                            <div>
                                <FormItem label="金额">{{ detailForm.cost }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.costMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in costFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="4. 录入业绩" name="performance">
                            <div>
                                <FormItem label="业绩金额">{{ detailForm.performance }}</FormItem>
                                <FormItem label="完成时间">{{ detailForm.completionTime }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.performanceMemo }}</FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="5. 尾款" name="balance">
                            <div>
                                <FormItem label="金额">{{ detailForm.balance }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.balanceMemo }}</FormItem>
                                <FormItem label="附件">
                                    <div class="upload-image-list" v-for="(item, index) in balanceFileArray">
                                        <img :src="item.imgData" style=""/>
                                        <div class="upload-image-list-cover">
                                            <Icon type="ios-eye" @click.native="handlePreview(item.imgData)"></Icon>
                                        </div>
                                    </div>
                                </FormItem>
                            </div>
                        </TabPane>
                        <TabPane label="6. 录入业绩" name="balPerformance">
                            <div>
                                <FormItem label="业绩金额">{{ detailForm.balPerformance }}</FormItem>
                                <FormItem label="完成时间">{{ detailForm.balPerformanceTime }}</FormItem>
                                <FormItem class="multiline-content" label="备注">{{ detailForm.balPerformanceMemo }}</FormItem>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <div class="divider">审批结论</div>
                <div class="custom-form-section">
                    <FormItem class="plain-item-margin" label="审批结论" required>
                        <Select v-model="detailForm.status" placeholder="请选择" filterable clearable>
                            <Option value="通过">通过</Option>
                            <Option value="驳回">驳回</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="驳回理由" required v-if="detailForm.status === '驳回'">
                        <Input type="textarea" v-model="detailForm.comment" placeholder="请输入" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit />
                    </FormItem>
                </div>
            </div>
        </Form>
        <Modal title="查看图片" v-model="showPreview" footer-hide :width="860">
            <img :src="imgView" v-if="showPreview" style="width: 100%"/>
        </Modal>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleApproval">确定</Button>
        </div>
        <view-resume v-model="showViewResumeModal" :id="resumeId"></view-resume>
    </Modal>
</template>
<script>
import {downloadFile} from "../../api/system-api";
import {reqGetDeliveryDetailByDahId, reqProcessBacklog} from "../../api/admin-api";
import viewResume from '../resume/view';

export default {
    components: {viewResume},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        backlogId: null,
        sourceId: null,
        sourceType: null,
    },
    data() {
        return {
            showForm: this.value,
            showViewResumeModal: false,
            loading: false,
            modalTitle: null,
            resumeId: null,
            showPreview: false,
            imgView: null,
            myPosition: {},
            detailForm: {
                id: null,
                status: null,
            },
            detailFormRule: {
                status: [
                    {required: true, message: '请选择审批结论', trigger: 'blur'}
                ],
                comment: [
                    {required: true, message: '请输入驳回理由', trigger: 'blur'}
                ]
            },
            receiveFileArray: [],
            paymentFileArray: [],
            overDueFileArray: [],
            balanceFileArray: [],
            costFileArray: [],
            recommendResumeList: [],
            bookingResumeList: [],
            presentResumeList: [],
            receiveResumeList: [],
            performanceResumeList: [],
            firstRoundResumeList: [],
            secondRoundResumeList: [],
            offerResumeList: [],
            entryResumeList: [],
            paymentResumeList: [],
            overDueResumeList: [],
            balanceResumeList: [],
            balPerformanceResumeList: [],
            columns: [
                {
                    title: 'ID',
                    key: 'id',
                    width: 80,
                },
                {
                    title: '姓名',
                    key: 'name',
                    width: 100,
                },
                {
                    title: '性别',
                    key: 'gender',
                    width: 80,
                },
                {
                    title: '年龄',
                    key: 'age',
                    width: 80,
                },
                {
                    title: '手机号码',
                    key: 'phone',
                    minWidth: 130,
                },
                {
                    title: '最高学历',
                    key: 'highestDegreeName',
                    minWidth: 120,
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 80,
                    render: (h, params, index) => {
                        let content = [h('a', {on: {'click': () => {this.handleReviewResume(params.row, index);}}}, '详情')];
                        return h('div', content);
                    }
                }
            ],
        }
    },
    methods: {
        getDeliveryDetailByDahId() {
            this.receiveFileArray = [];

            reqGetDeliveryDetailByDahId({dahId: this.sourceId}).then((res) => {
                this.myPosition = res.data.data.position;
                this.modalTitle = '职位交付 (ID:' + this.myPosition.custId + ')';
                this.detailForm = res.data.data.delivery;
                this.detailForm.status = null;
                this.recommendResumeList = res.data.data.resumeList.filter(item => item.type === 'recommend');
                this.bookingResumeList = res.data.data.resumeList.filter(item => item.type === 'booking');
                this.presentResumeList = res.data.data.resumeList.filter(item => item.type === 'present');
                this.receiveResumeList = res.data.data.resumeList.filter(item => item.type === 'receive');
                this.performanceResumeList = res.data.data.resumeList.filter(item => item.type === 'performance');

                this.firstRoundResumeList = res.data.data.resumeList.filter(item => item.type === 'firstRound');
                this.secondRoundResumeList = res.data.data.resumeList.filter(item => item.type === 'secondRound');
                this.offerResumeList = res.data.data.resumeList.filter(item => item.type === 'offer');
                this.entryResumeList = res.data.data.resumeList.filter(item => item.type === 'entry');
                this.paymentResumeList = res.data.data.resumeList.filter(item => item.type === 'payment');
                this.overDueResumeList = res.data.data.resumeList.filter(item => item.type === 'overDue');
                this.balanceResumeList = res.data.data.resumeList.filter(item => item.type === 'balance');
                this.balPerformanceResumeList = res.data.data.resumeList.filter(item => item.type === 'balPerformance');

                this.detailForm.receiveFile && JSON.parse(this.detailForm.receiveFile).forEach(name => {
                    downloadFile({fileName: name, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.receiveFileArray.push({imgName: name, imgData: myData});
                    });
                });
                this.detailForm.paymentFile && JSON.parse(this.detailForm.paymentFile).forEach(name => {
                    downloadFile({fileName: name, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.paymentFileArray.push({imgName: name, imgData: myData});
                    });
                });
                this.detailForm.overDueFile && JSON.parse(this.detailForm.overDueFile).forEach(name => {
                    downloadFile({fileName: name, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.overDueFileArray.push({imgName: name, imgData: myData});
                    });
                });
                this.detailForm.balanceFile && JSON.parse(this.detailForm.balanceFile).forEach(name => {
                    downloadFile({fileName: name, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.balanceFileArray.push({imgName: name, imgData: myData});
                    });
                });
                this.detailForm.costFile && JSON.parse(this.detailForm.costFile).forEach(name => {
                    downloadFile({fileName: name, type: 'private'}).then((res2) => {
                        let myData = window.URL.createObjectURL(new Blob([res2.data]));
                        this.costFileArray.push({imgName: name, imgData: myData});
                    });
                });

            }).catch(() => {
                this.recommendResumeList = [];
                this.bookingResumeList = [];
                this.presentResumeList = [];
                this.receiveResumeList = [];
                this.performanceResumeList = [];
                this.receiveFileArray = [];
                this.paymentFileArray = [];
                this.overDueFileArray = [];
                this.balanceFileArray = [];
            })
        },
        handleReviewResume(row, index) {
            this.showViewResumeModal = true;
            this.resumeId = row.id;
        },
        handlePreview(imgData) {
            this.imgView = imgData;
            this.showPreview = true;
        },
        handleApproval() {
            this.loading = true;
            this.$refs['detailForm'].validate((valid) => {
                if (!valid) {
                    this.loading = false;
                    return false;
                }
                if (this.detailForm.status === '驳回' && this.detailForm.comment === undefined) {
                    this.$Message.error('请输入驳回理由')
                    this.loading = false;
                    return false;
                }

                let params = {
                    backlogId: this.backlogId,
                    sourceId: this.sourceId,
                    sourceType: this.sourceType,
                    status: this.detailForm.status,
                    comment: this.detailForm.comment
                };
                reqProcessBacklog(params).then((res) => {
                    this.loading = false;
                    if (res.data.data === 1) {
                        this.$Message.success('审批成功');
                        this.$emit('getBackLog');
                        this.closeModal(false);
                    } else if(res.data.data === -1){
                        this.$Message.error('请选择审批结论')
                    }else {
                        this.$Message.error('审批失败');
                    }
                }).catch(()=>{
                    this.loading = false;
                    this.$Message.error('审批失败');
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.$refs['detailForm'].resetFields();
                this.getDeliveryDetailByDahId();
            } else {// 反之则关闭页面
                this.resumeId = null;
                this.showPreview = false;
                this.imgView = null;
                this.myPosition = {};
                this.receiveFileArray = [];
                this.paymentFileArray = [];
                this.overDueFileArray = [];
                this.balanceFileArray = [];
                this.costFileArray = [];

                this.recommendResumeList = [];
                this.bookingResumeList = [];
                this.presentResumeList = [];
                this.receiveResumeList = [];
                this.performanceResumeList = [];
                this.firstRoundResumeList = [];
                this.secondRoundResumeList = [];
                this.offerResumeList = [];
                this.entryResumeList = [];
                this.paymentResumeList = [];
                this.overDueResumeList = [];
                this.balanceResumeList = [];
                this.balPerformanceResumeList = [];
                this.closeModal(val);
            }
        }
    }
}
</script>
