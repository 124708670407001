<template>
    <Modal v-model="showForm" title="审批详情" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" label-colon :label-width="90">
            <FormItem label="标题">{{ viewForm.title }}</FormItem>
            <FormItem label="内容">{{ viewForm.content }}</FormItem>
            <FormItem label="类型">{{ viewForm.type }}</FormItem>
            <FormItem label="提交人">{{ viewForm.senderName }}</FormItem>
            <FormItem label="提交时间">{{ viewForm.sendTime }}</FormItem>
            <FormItem label="处理人">{{ viewForm.handlerName }}</FormItem>
            <FormItem label="处理状态">{{ viewForm.status }}</FormItem>
            <FormItem label="驳回理由" v-if="viewForm.comment !== null">{{ viewForm.comment }}</FormItem>
            <FormItem label="处理时间">{{ viewForm.handlingTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {
    reqGetDeliveryHistoryById,
    reqGetGoalHistoryById,
    reqGetOpportunityHistoryById,
} from "@/api/admin-api";

export default {
    name: "viewBackLog",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {}
    },
    data() {
        return {
            showForm: this.value,
            viewForm: {},
        }
    },
    methods: {
        getOpportunityHistoryById() {
            reqGetOpportunityHistoryById({id: this.data.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.viewForm = this.data
                    this.viewForm.comment = res.data.data.comment
                } else {
                    this.viewForm = {}
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        getDeliveryHistoryById() {
            reqGetDeliveryHistoryById({id: this.data.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.viewForm = this.data
                    this.viewForm.comment = res.data.data.comment
                } else {
                    this.viewForm = {}
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        getGoalHistoryById() {
            reqGetGoalHistoryById({id: this.data.sourceId}).then((res) => {
                if (res.data.code === 1) {
                    this.viewForm = this.data
                    this.viewForm.comment = res.data.data.comment
                } else {
                    this.viewForm = {}
                }
            }).catch(() => {
                this.viewForm = {}
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                if (this.data.sourceType === 'position_interview_performance' || this.data.sourceType === 'position_entry_performance'
                    || this.data.sourceType === 'position_entry_balPerformance' || this.data.sourceType === 'position_RPO_other_cost'
                    || this.data.sourceType === 'position_RPO_other_performance' || this.data.sourceType === 'position_RPO_other_balPerformance') {
                    this.getDeliveryHistoryById()
                }
                if (this.data.sourceType === 'opportunity_cost' || this.data.sourceType === 'opportunity_performance') {
                    this.getOpportunityHistoryById()
                }
                if (this.data.sourceType === 'goal_confirm' || this.data.sourceType === 'goal_change') {
                    this.getGoalHistoryById()
                }
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
